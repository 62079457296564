import store from '@/store'
import http from '@/utils/http'
// import qs from 'qs'

let preApi = '';
if (store.state.mock && process.env.NODE_ENV == 'development') {
  preApi = '/mock';
}

// 更新事件历史
export function modifyEventrecord(params) {
  return http({
    url: `${preApi}/event/modifyEventrecord`,
    method: "post",
    data: params
  })
}

// 7.4.11.	查询事件历史
export function getEventrecordListByCondition(params) {
  return http({
    url: `${preApi}/event/getEventrecordListByCondition`,
    method: "post",
    data: params
  })
}
//查询事件个数
export function getEventrecordCountByCondition(params) {
  return http({
    url: `${preApi}/event/getEventrecordCountByCondition`,
    method: "post",
    data: params
  })
}
// 7.4.12.	删除事件
export function deleteEventrecord(params) {
  return http({
    url: `${preApi}/event/deleteEventrecord`,
    method: "post",
    data: params
  })
}
// 7.4.13.	添加事件代码条目
export function addEvent(params) {
  return http({
    url: `${preApi}/event/addEvent`,
    method: "post",
    data: params
  })
}
// 7.4.14.	修改事件代码条目
export function modifyEvent(params) {
  return http({
    url: `${preApi}/event/modifyEvent`,
    method: "post",
    data: params
  })
}
// 7.4.15.	查询事件代码条目列表
export function getEventListByCondition(params) {
  return http({
    url: `${preApi}/event/getEventListByCondition`,
    method: "post",
    data: params
  })
}

//删除测试事件列表
export function deleteNullEventrecord(params) {
  return http({
    url: `${preApi}/event/deleteNullEventrecord`,
    method: "post",
    data: params
  })
}

// 7.4.16.	查询事件数量列表
export function getEventListCountByCondition(params) {
  return http({
    url: `${preApi}/event/getEventListCountByCondition`,
    method: "post",
    data: params
  })
}
//导出事件/故障/报警历史excel
export function exportEventrecordExcel(params) {
  return http({
    url: `${preApi}/event/exportEventrecordExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

// 7.4.17.	删除事件代码条目
export function deleteEvent(params) {
  return http({
    url: `${preApi}/event/deleteEvent`,
    method: "post",
    data: params
  })
}
// 7.4.24.	查询运行状态(追溯)
export function getDevicestatusListByCondition(params) {
  return http({
    url: `${preApi}/device/getDevicestatusListByCondition`,
    method: "post",
    data: params
  })
}
// 7.5.1.	物联网大数据主视图
export function getIotMaindata(params) {
  return http({
    url: `${preApi}/bigdata/getIotMaindata`,
    method: "post",
    data: params
  })
}
// 7.5.2.	物联网大数据地图视图
export function getIotMapdata(params) {
  return http({
    url: `${preApi}/bigdata/getIotMapdata`,
    method: "post",
    data: params
  })
}
// 7.5.3.	物联网大数据事件统计
export function getIotDateevent(params) {
  return http({
    url: `${preApi}/bigdata/getIotDateevent`,
    method: "post",
    data: params
  })
}
// 7.5.4.	物联网大数据故障类型统计
export function getIotTypeevent(params) {
  return http({
    url: `${preApi}/bigdata/getIotTypeevent`,
    method: "post",
    data: params
  })
}

export function getAllIotTypeevent(params) {
  return http({
    url: `${preApi}/bigdata/getAllIotTypeevent`,
    method: "post",
    data: params
  })
}
// 7.5.5.	物联网大数据电梯品牌统计
export function getIotBranddata(params) {
  return http({
    url: `${preApi}/bigdata/getIotBranddata`,
    method: "post",
    data: params
  })
}
// 7.5.6.	物联网大数据月度趋势数据统计
export function getIotMonthevent(params) {
  return http({
    url: `${preApi}/bigdata/getIotMonthevent`,
    method: "post",
    data: params
  })
}
export function getAllIotMonthevent(params) {
  return http({
    url: `${preApi}/bigdata/getAllIotMonthevent`,
    method: "post",
    data: params
  })
}
// 7.5.7.	物联网大数据场所困人统计
export function getIotPlaceevent(params) {
  return http({
    url: `${preApi}/bigdata/getIotPlaceevent`,
    method: "post",
    data: params
  })
}

export function getAllUserdepLists(params) {
  return http({
    url: `${preApi}/userdep/getAllUserdepLists`,
    method: "post",
    data: params
  })
}

export function getAllLiftinfoLists(params) {
  return http({
    url: `${preApi}/lift/getAllLiftinfoLists`,
    method: "post",
    data: params
  })
}

export function getAreaByLift(params) {
  return http({
    url: `${preApi}/area/getAreaByLift`,
    method: "post",
    data: params
  })
}

//获取事件视频文件地址
export function getVideoRecordingUrl(params) {
  return http({
    url: `${preApi}/event/getVideoRecordingUrl`,
    method: "post",
    data: params
  })
}