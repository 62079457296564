// 基础功能
import store from '@/store'
import http from '@/utils/http'

let preApi = '';
if(store.state.mock && process.env.NODE_ENV == 'development') {
  preApi = '/mock';
}

// 设备列表查询
export function getDeviceinfoListByCondition(params) {
  return http({
    url: `${preApi}/device/getDeviceinfoListByCondition`,
    method: "post",
    data: params
  })
}

// 设备列表查询
export function getTestDeviceinfoListByCondition(params) {
  return http({
    url: `${preApi}/device/getTestDeviceinfoListByCondition`,
    method: "post",
    data: params
  })
}

// 电梯关联设备
export function getLiftDeviceinfoListByCondition(params) {
  return http({
    url: `${preApi}/device/getLiftDeviceinfoListByCondition`,
    method: "post",
    data: params
  })
}

// 删除设备
export function deleteDeviceinfo(params) {
  return http({
    url: `${preApi}/device/deleteDeviceinfo`,
    method: "post",
    data: params
  })
}
// 新增设备
export function addDeviceInfo(params) {
  return http({
    url: `${preApi}/device/addDeviceInfo`,
    method: "post",
    data: params
  })
}
// 修改设备
export function modifyDeviceInfo(params) {
  return http({
    url: `${preApi}/device/modifyDeviceInfo`,
    method: "post",
    data: params
  })
}

// 显示屏列表查询
export function getMonitorinfoListByCondition(params) {
  return http({
    url: `${preApi}/device/getMonitorinfoListByCondition`,
    method: "post",
    data: params
  })
}

// 电梯关联显示屏设备
export function getLiftMonitorinfoListByCondition(params) {
  return http({
    url: `${preApi}/device/getLiftMonitorinfoListByCondition`,
    method: "post",
    data: params
  })
}

// 删除显示屏设备
export function deleteMonitorinfo(params) {
  return http({
    url: `${preApi}/device/deleteMonitorinfo`,
    method: "post",
    data: params
  })
}
// 新增显示屏设备
export function addMonitorInfo(params) {
  return http({
    url: `${preApi}/device/addMonitorInfo`,
    method: "post",
    data: params
  })
}
// 修改显示屏设备
export function modifyMonitorInfo(params) {
  return http({
    url: `${preApi}/device/modifyMonitorInfo`,
    method: "post",
    data: params
  })
}

// 显示屏型号列表查询
export function getMonitortypeListByCondition(params) {
  return http({
    url: `${preApi}/device/getMonitortypeListByCondition`,
    method: "post",
    data: params
  })
}

// 服务器信息添加
export function addServerSystem(params) {
  return http({
    url: `${preApi}/server/addServerSystem`,
    method: "post",
    data: params
  })
}

// 服务器信息更新
export function modifyServerSystem(params) {
  return http({
    url: `${preApi}/server/modifyServerSystem`,
    method: "post",
    data: params
  })
}

// 服务器信息删除
export function deleteServerSystem(params) {
  return http({
    url: `${preApi}/server/deleteServerSystem`,
    method: "post",
    data: params
  })
}

// 服务器信息查询
export function getServerSystemListByCondition(params) {
  return http({
    url: `${preApi}/server/getServerSystemListByCondition`,
    method: "post",
    data: params
  })
}

// 设备型号列表查询
export function getTerminaltypeListByCondition(params) {
  return http({
    url: `${preApi}/device/getTerminaltypeListByCondition`,
    method: "post",
    data: params
  })
}
// 删除设备型号
export function deleteTerminaltype(params) {
  return http({
    url: `${preApi}/device/deleteTerminaltype`,
    method: "post",
    data: params
  })
}
// 新增设备型号
export function addTerminaltype(params) {
  return http({
    url: `${preApi}/device/addTerminaltype`,
    method: "post",
    data: params
  })
}
// 修改设备型号
export function modifyTerminaltype(params) {
  return http({
    url: `${preApi}/device/modifyTerminaltype`,
    method: "post",
    data: params
  })
}
export function getDeviceinfoVersionListByCondition(params) {
  return http({
    url: `${preApi}/device/getDeviceinfoVersionListByCondition`,
    method: "post",
    data: params
  })
}
export function getDeviceVersionMap(params) {
  return http({
    url: `${preApi}/device/getDeviceVersionMap`,
    method: "post",
    data: params
  })
}

// 设备模块列表查询
export function getDevicemoduleinfoListByCondition(params) {
  return http({
    url: `${preApi}/device/getDevicemoduleinfoListByCondition`,
    method: "post",
    data: params
  })
}
// 删除设备模块
export function deleteDevicemoduleinfo(params) {
  return http({
    url: `${preApi}/device/deleteDevicemoduleinfo`,
    method: "post",
    data: params
  })
}
// 新增设备模块
export function addDevicemoduleinfo(params) {
  return http({
    url: `${preApi}/device/addDevicemoduleinfo`,
    method: "post",
    data: params
  })
}
// 修改设备模块
export function modifyDevicemoduleInfo(params) {
  return http({
    url: `${preApi}/device/modifyDevicemoduleInfo`,
    method: "post",
    data: params
  })
}

// 设备组列表查询
export function getDevicegroupListByCondition(params) {
  return http({
    url: `${preApi}/device/getDevicegroupListByCondition`,
    method: "post",
    data: params
  })
}
// 删除设备组
export function deleteDevicegroup(params) {
  return http({
    url: `${preApi}/device/deleteDevicegroup`,
    method: "post",
    data: params
  })
}
// 新增设备组
export function addDevicegroup(params) {
  return http({
    url: `${preApi}/device/addDevicegroup`,
    method: "post",
    data: params
  })
}
// 修改设备组
export function modifyDevicegroup(params) {
  return http({
    url: `${preApi}/device/modifyDevicegroup`,
    method: "post",
    data: params
  })
}
// 查询关联设备
export function getDevicegroupdevListByCondition(params) {
  return http({
    url: `${preApi}/device/getDevicegroupdevListByCondition`,
    method: "post",
    data: params
  })
}
// 添加关联设备
export function addDevicegroupdev(params) {
  return http({
    url: `${preApi}/device/addDevicegroupdev`,
    method: "post",
    data: params
  })
}
// 删除关联设备
export function deleteDevicegroupdev(params) {
  return http({
    url: `${preApi}/device/deleteDevicegroupdev`,
    method: "post",
    data: params
  })
}

// 参数列表查询
export function getDeviceparamListByCondition(params) {
  return http({
    url: `${preApi}/device/getDeviceparamListByCondition`,
    method: "post",
    data: params
  })
}
// 参数列表查询
export function getDeviceparamChildLisByParentId(params) {
  return http({
    url: `${preApi}/device/getDeviceparamChildLisByParentId`,
    method: "post",
    data: params
  })
}
// 删除参数
export function deleteDeviceparam(params) {
  return http({
    url: `${preApi}/device/deleteDeviceparam`,
    method: "post",
    data: params
  })
}
// 新增参数
export function addDeviceparam(params) {
  return http({
    url: `${preApi}/device/addDeviceparam`,
    method: "post",
    data: params
  })
}
// 修改参数
export function modifyDeviceparam(params) {
  return http({
    url: `${preApi}/device/modifyDeviceparam`,
    method: "post",
    data: params
  })
}
// 获取设备模块参数值
export function getDeviceparamValueList(params) {
  return http({
    // url: `/mock/device/getDeviceparamValueList`,
    url: `${preApi}/device/getDeviceparamValueList`,
    method: "post",
    data: params
  })
}
// 设置设备模块参数值
export function setDeviceparamValueList(params) {
  return http({
    url: `${preApi}/device/setDeviceparamValueList`,
    method: "post",
    data: params
  })
}

// 获取设备模块参数值
export function getDeviceAudioparamValueList(params) {
  return http({
    // url: `/mock/device/getDeviceparamValueList`,
    url: `${preApi}/device/getDeviceAudioparamValueList`,
    method: "post",
    data: params
  })
}
// 设置设备模块参数值
export function setDeviceAudioparamValueList(params) {
  return http({
    url: `${preApi}/device/setDeviceAudioparamValueList`,
    method: "post",
    data: params
  })
}

// 事件列表查询
export function getThirdeventListByCondition(params) {
  return http({
    url: `${preApi}/event/getThirdeventListByCondition`,
    method: "post",
    data: params
  })
}
// 删除事件
export function deleteThirdevent(params) {
  return http({
    url: `${preApi}/event/deleteThirdevent`,
    method: "post",
    data: params
  })
}
// 新增事件
export function addThirdEvent(params) {
  return http({
    url: `${preApi}/event/addThirdEvent`,
    method: "post",
    data: params
  })
}
// 修改事件
export function modifyThirdevent(params) {
  return http({
    url: `${preApi}/event/modifyThirdevent`,
    method: "post",
    data: params
  })
}

// 查询自检日志
export function getDevicecheckListByCondition(params) {
  return http({
    url: `${preApi}/device/getDevicecheckListByCondition`,
    method: "post",
    data: params
  })
}
// 删除自检日志 devicecheckid
export function deleteDevicecheck(params) {
  return http({
    url: `${preApi}/device/deleteDevicecheck`,
    method: "post",
    data: params
  })
}

// 7.4.47.	获取标注图片分辨率
export function getMarkpointSize(params) {
  return http({
    url: `${preApi}/device/getMarkpointSize`,
    method: "post",
    data: params
  })
}
// 7.4.46.	设置标注
export function sentMarkpoint(params) {
  return http({
    url: `${preApi}/device/sentMarkpoint`,
    method: "post",
    data: params
  })
}

//添加标注
export function addMarkpoint(params) {
  return http({
    url: `${preApi}/device/addMarkpoint`,
    method: "post",
    data: params
  })
}

//更新标注
export function updateMarkpoint(params) {
  return http({
    url: `${preApi}/device/updateMarkpoint`,
    method: "post",
    data: params
  })
}

//删除标注
export function deleteMarkPoint(params){
  return http({
    url: `${preApi}/device/deleteMarkpoint`,
    method: "post",
    data: params
  })
}

//查询标注
export function findMarkpoint(params) {
  return http({
    url: `${preApi}/device/findMarkpoint`,
    method: "post",
    data: params
  })
}

// 查询版本列表
export function getDeviceversionListByCondition(params) {
  return http({
    url: `${preApi}/device/getDeviceversionListByCondition`,
    method: "post",
    data: params
  })
}
// 查询版本详情
export function getDeviceversionInfo(params) {
  return http({
    url: `${preApi}/device/getDeviceversionInfo`,
    method: "post",
    data: params
  })
}
// 添加版本
export function addDeviceversion(params) {
  return http({
    url: `${preApi}/device/addDeviceversion`,
    method: "post",
    data: params
  })
}
// 修改版本
export function modifyDeviceversion(params) {
  return http({
    url: `${preApi}/device/modifyDeviceversion`,
    method: "post",
    data: params
  })
}
// 修改版本
export function deleteDeviceversion(params) {
  return http({
    url: `${preApi}/device/deleteDeviceversion`,
    method: "post",
    data: params
  })
}
// 修改设备版本
export function updateVersion(params) {
  return http({
    url: `${preApi}/device/updateVersion`,
    method: "post",
    data: params
  })
}

//添加重启日志
export function addRecoverLog(params) {
  return http({
    url: `${preApi}/device/addRecoverLog`,
    method: "post",
    data: params
  })
}
//更新重启日志版本
export function modifyRecoverLog(params) {
  return http({
    url: `${preApi}/device/modifyRecoverLog`,
    method: "post",
    data: params
  })
}
//删除重启日志版本
export function deleteRecoverLog(params) {
  return http({
    url: `${preApi}/device/deleteRecoverLog`,
    method: "post",
    data: params
  })
}
// 查看重启日志
export function findRecoverLogListByCondition(params) {
  return http({
    url: `${preApi}/device/findRecoverLogListByCondition`,
    method: "post",
    data: params
  })
}

//添加参数批量下发日志
export function addParamsBatchLog(params) {
  return http({
    url: `${preApi}/device/addParamsBatchLog`,
    method: "post",
    data: params
  })
}
//更新参数批量下发日志版本
export function modifyParamsBatchLog(params) {
  return http({
    url: `${preApi}/device/modifyParamsBatchLog`,
    method: "post",
    data: params
  })
}
//删除参数批量下发日志版本
export function deleteParamsBatchLog(params) {
  return http({
    url: `${preApi}/device/deleteParamsBatchLog`,
    method: "post",
    data: params
  })
}
// 查看参数批量下发日志
export function findParamsBatchLogListByCondition(params) {
  return http({
    url: `${preApi}/device/findParamsBatchLogListByCondition`,
    method: "post",
    data: params
  })
}

// 重启设备组
export function recoverDevice(params) {
  return http({
    url: `${preApi}/device/recoverDevice`,
    method: "post",
    data: params
  })
}

//添加升级日志
export function addUpgradeLog(params) {
  return http({
    url: `${preApi}/device/addUpgradeLog`,
    method: "post",
    data: params
  })
}
//更新升级日志版本
export function upgradeVersion(params) {
  return http({
    url: `${preApi}/device/upgradeVersion`,
    method: "post",
    data: params
  })
}
//删除升级日志版本
export function deleteUpgradeLog(params) {
  return http({
    url: `${preApi}/device/deleteUpgradeLog`,
    method: "post",
    data: params
  })
}
// 查看升级日志
export function findUpgradeLogListByCondition(params) {
  return http({
    url: `${preApi}/device/findUpgradeLogListByCondition`,
    method: "post",
    data: params
  })
}

// 查看升级日志版本号对比
export function calculateVersion(params) {
  return http({
    url: `${preApi}/device/calculateVersion`,
    method: "post",
    data: params
  })
}

// 查询参数下发日志
export function getDevparamrecordListByCondition(params) {
  return http({
    url: `${preApi}/device/getDevparamrecordListByCondition`,
    method: "post",
    data: params
  })
}
// 删除参数下发日志
export function deleteDevparamrecord(params) {
  return http({
    url: `${preApi}/device/deleteDevparamrecord`,
    method: "post",
    data: params
  })
}

//查询平台自检日志列表
export function findSelfTestLogListByCondition(params) {
  return http({
    url: `${preApi}/selftest/findSelfTestLogListByCondition`,
    method: "post",
    data: params
  })
}

//查询终端自检日志列表
export function findDevTestLogListByCondition(params) {
  return http({
    url: `${preApi}/selftest/findDevTestLogListByCondition`,
    method: "post",
    data: params
  })
}

export function findDevAbnormalDevListByCondition(params) {
  return http({
    url: `${preApi}/selftest/findDevAbnormalDevListByCondition`,
    method: "post",
    data: params
  })
}

//查询终端自检日志列表
export function findDevAbnormalData(params) {
  return http({
    url: `${preApi}/selftest/findDevAbnormalData`,
    method: "post",
    timeout: 120 * 1000,
    data: params
  })
}

//查询TF卡异常列表
export function findTFAbnormalListByCondition(params) {
  return http({
    url: `${preApi}/selftest/findTFAbnormalListByCondition`,
    method: "post",
    data: params
  })
}

//查询平台自检日志列表
export function findPlatAbnormalData(params) {
  return http({
    url: `${preApi}/selftest/findPlatAbnormalData`,
    method: "post",
    data: params
  })
}

//查询设备离线列表
export function findDeviceOfflineListByCondition(params) {
  return http({
    url: `${preApi}/device/findDeviceOfflineListByCondition`,
    method: "post",
    data: params
  })
}


//查询终端自检运行数据
export function findDevAbnormalListByCondition(params) {
  return http({
    url: `${preApi}/device/findDevAbnormalListByCondition`,
    method: "post",
    data: params
  })
}

//重启SRS
export function serverReboot(params) {
  return http({
    url: `${preApi}/server/serverReboot`,
    method: "post",
    data: params
  })
}

//重启SRS
export function shutdownServer(params) {
  return http({
    url: `${preApi}/server/shutdownServer`,
    method: "post",
    data: params
  })
}

export function getAbnormalStreamListByCondition(params) {
  return http({
    url: `${preApi}/deviceinfo/getAbnormalStreamListByCondition`,
    method: "post",
    data: params
  })
}

export function deleteAbnormalStream(params) {
  return http({
    url: `${preApi}/deviceinfo/deleteAbnormalStream`,
    method: "post",
    data: params
  })
}
