<template>
  <page-container :title="breadcrumb" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="救援码" prop="liftrescue">
              <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="安装地址" prop="adress">
              <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepid">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择使用单位" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维护单位" prop="maintenancedepid">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions.filter(item => item.deptype=='3')" v-model="maintenancedepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择维护单位" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="故障时间" prop="installendtime">
              <a-range-picker v-model="queryInstallDate"></a-range-picker>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">事件解除</h3>
          <div class="table-btns">
            <a-button type="primary" @click="deleteBatch()">删除</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :row-selection="{onChange: rowSelectChange}" :columns="tableColumns" :data-source="tableData" row-key="eventrecordid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="eventstatus" slot-scope="value">
            <a-tag :color="eventstatusColors[value]||''">{{eventstatus[value]||''}}</a-tag>
          </span>
          <span slot="eventname" :title="value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''" slot-scope="value, record">{{value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''}}</span>
          <span slot="starttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="endtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="bjjc" slot-scope="value, record">
            <a-button type="link" size="small" @click="handleAscend(value,record)">解除</a-button>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-button type="link" size="small" @click="deleteConfirm(value, record)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </page-container>
</template>

<script>
import { getItemFromArrayByKey, getTimeRange } from 'U'
import moment from 'moment'
import {mapState} from "vuex";
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import {titleLists,eventTypes,eventstatus,eventstatusColors} from '@/json/wlwhistory'
import { getEventrecordListByCondition,deleteEventrecord } from 'A/wlw.js'
import {getDeviceparamValueList, setDeviceparamValueList} from "A/ai";
import callCenter from "C/modals/callCenter";


export default {
  name: 'elecarEvent',
  mixins: [ areaselect, deptselect,pagination],
  props: ['pageType'],
  data() {
    return {
      moment,
      eventstatus,
      eventstatusColors,
      showAdvanced: false,
      breadcrumb: [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '事件解除',
          path: ''
        },
      ],
      queryParams: {
        liftnum: '',
        liftrescue: '',
        userdepid: '',
        maintenancedepid: '',
        maintenancegroupid: '',
        adress: '',
        areacode: '',
        starttime: '',
        endtime: '',
      },
      aiConfigs:{
        heartbeat_cycle:'',
        // version_number:'',
        INIT_WITH_VEHICLE_THRESHOLD:'',
        INIT_WITH_VEHICLE_HEAD_THRESHOLD:'',
        INIT_WITH_VEHICLE_CHAIR_THRESHOLD:'',
        INIT_WITH_FIGHT_THRESHOLD:'',
        INIT_WITH_FISTHEAD_THRESHOLD:'',
        VEHICLE_QUEUE_SIZE:'',
        VEHICLE_QUEUE_NUM:'',
        VEHICLE_CHAIR_HEAD_SIZE:'',
        VEHICLE_CHAIR_HEAD_NUM:'',
        FIGHT_QUEUE_SIZE:'',
        FIGHT_QUEUE_NUM:'',
        FISTHEAD_QUEUE_SIZE:'',
        FISTHEAD_QUEUE_NUM:'',
        BLOCK_DOOR_LIST_MAXLEN:'',
        BLOCK_DOOR_LIST_NUM:'',
        temp_threshold:'',
        door_area:'',
        ev_event_enable:'',
        ev_event_cancel:'',
        abnormal_event_enable:'',
        fisthead_enable:'',
        block_event_enable:'',
        LISTEN_PORT:'',
        mqtt_port:'',
        iot_port:'',
        PERSON_STATUS_LIST_MAXLEN:'',
        PERSON_STATUS_LIST_NUM:'',
        DOOR_STATUS_LIST_MAXLEN:'',
        DOOR_STATUS_LIST_NUM:'',
        Ev_event_code:'',
        Ev_event_cancle_code:'',
        Block_event_code:'',
        Block_event_cancle_code:'',
        Abnormal_event_code:'',
        Abnormal_event_cancle_code:'',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      maintenancedepidCascaderSelected: [],
      maintenancegroupidOptions: [],
      tableData: [],
      tableLoading: false,
      batchrowids: '',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    tableColumns(){
      return [
        {
          title: '救援码',
          dataIndex: 'liftrescue',
          key: 'liftrescue',
          ellipsis: true,
        },
        {
          title: '电梯注册代码',
          dataIndex: 'liftnum',
          key: 'liftnum',
          ellipsis: true,
        },
        {
          title: '安装地址',
          dataIndex: 'adress',
          key: 'adress',
          ellipsis: true,
        }, {
          title: '事件名',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
        },{
          title: '事件开始时间',
          dataIndex: 'eventstarttime',
          key:'eventstarttime',
          ellipsis: true,
          scopedSlots: { customRender: 'starttime' }
        },{
          title: '事件解除时间',
          dataIndex: 'eventendtime',
          key: 'eventendtime',
          ellipsis: true,
          scopedSlots: { customRender: 'endtime' }
        }, {
          title: '事件状态',
          dataIndex: 'eventstatus',
          key: 'eventstatus',
          ellipsis: true,
          scopedSlots: { customRender: 'eventstatus' }
        },{
          title: '解除报警',
          dataIndex: 'bjjc',
          align:'center',
          width: 100,
          scopedSlots: { customRender: 'bjjc' }
        },{
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 50,
          scopedSlots: { customRender: 'operation' }
        },
      ]
    },
  },
  watch: {
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
      }else {
        this.queryParams.userdepid = '';
      }
    },
    maintenancedepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.maintenancedepid = val[val.length-1];
        this.initMaintenancegroupidOptions();
      }else {
        this.queryParams.maintenancedepid = '';
        this.maintenancegroupidOptions = [];
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = null;
      this.userdepidCascaderSelected = [];
      this.maintenancedepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        //...this.queryParams,
        eventstatus:'1',
        eventtype:'3',
        eventcode:'03',
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getEventrecordListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.eventrecordid);
        },
        onCancel() {},
      });
    },
    delete(eventrecordid) {
      this.showLoading();
      if(eventrecordid) {
        let params = {
          eventrecordid
        };
        deleteEventrecord(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm({
        // title: '',
        content: '确定要删除选中数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(this.batchrowids);
        },
        onCancel() {},
      });
    },
    getParamsConfig(devicenum) {
      this.paramsList = [];
      // let selectedMethod = getItemFromArrayByKey(this.methodList, 'devparamcfgid', this.tabKey);
      // console.log(selectedMethod)
      // if(selectedMethod) {
      let params = {
        devicenum: devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: '',
            params: {
              methord: 'GET',
              url: '/ai',
            }
          }
        ]
      };
      getDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
          this.aiConfigs.heartbeat_cycle=res.item[0].params.body.heartbeat_cycle
          // this.aiConfigs.version_number=res.item[0].params.body.version_number
          this.aiConfigs.INIT_WITH_VEHICLE_THRESHOLD=res.item[0].params.body.INIT_WITH_VEHICLE_THRESHOLD
          this.aiConfigs.INIT_WITH_VEHICLE_HEAD_THRESHOLD=res.item[0].params.body.INIT_WITH_VEHICLE_HEAD_THRESHOLD
          this.aiConfigs.INIT_WITH_VEHICLE_CHAIR_THRESHOLD=res.item[0].params.body.INIT_WITH_VEHICLE_CHAIR_THRESHOLD
          this.aiConfigs.INIT_WITH_FIGHT_THRESHOLD=res.item[0].params.body.INIT_WITH_FIGHT_THRESHOLD
          this.aiConfigs.INIT_WITH_FISTHEAD_THRESHOLD=res.item[0].params.body.INIT_WITH_FISTHEAD_THRESHOLD
          this.aiConfigs.VEHICLE_QUEUE_SIZE=res.item[0].params.body.VEHICLE_QUEUE_SIZE
          this.aiConfigs.VEHICLE_QUEUE_NUM=res.item[0].params.body.VEHICLE_QUEUE_NUM
          this.aiConfigs.VEHICLE_CHAIR_HEAD_SIZE=res.item[0].params.body.VEHICLE_CHAIR_HEAD_SIZE=
              this.aiConfigs.VEHICLE_CHAIR_HEAD_NUM=res.item[0].params.body.VEHICLE_CHAIR_HEAD_NUM
          this.aiConfigs.FIGHT_QUEUE_SIZE=res.item[0].params.body.FIGHT_QUEUE_SIZE
          this.aiConfigs.FIGHT_QUEUE_NUM=res.item[0].params.body.FIGHT_QUEUE_NUM
          this.aiConfigs.FISTHEAD_QUEUE_SIZE=res.item[0].params.body.FISTHEAD_QUEUE_SIZE
          this.aiConfigs.FISTHEAD_QUEUE_NUM=res.item[0].params.body.FISTHEAD_QUEUE_NUM
          this.aiConfigs.BLOCK_DOOR_LIST_MAXLEN=res.item[0].params.body.BLOCK_DOOR_LIST_MAXLEN
          this.aiConfigs.BLOCK_DOOR_LIST_NUM=res.item[0].params.body.BLOCK_DOOR_LIST_NUM
          this.aiConfigs.temp_threshold=res.item[0].params.body.temp_threshold
          this.aiConfigs.door_area=res.item[0].params.body.door_area
          this.aiConfigs.ev_event_enable=res.item[0].params.body.ev_event_enable
          this.aiConfigs.ev_event_cancel=res.item[0].params.body.ev_event_cancel
          this.aiConfigs.abnormal_event_enable=res.item[0].params.body.abnormal_event_enable
          this.aiConfigs.fisthead_enable=res.item[0].params.body.fisthead_enable
          this.aiConfigs.block_event_enable=res.item[0].params.body.block_event_enable
          this.aiConfigs.LISTEN_PORT=res.item[0].params.body.LISTEN_PORT
          this.aiConfigs.mqtt_port=res.item[0].params.body.mqtt_port
          this.aiConfigs.iot_port=res.item[0].params.body.iot_port
          this.aiConfigs.PERSON_STATUS_LIST_MAXLEN=res.item[0].params.body.PERSON_STATUS_LIST_MAXLEN
          this.aiConfigs.PERSON_STATUS_LIST_NUM=res.item[0].params.body.PERSON_STATUS_LIST_NUM
          this.aiConfigs.DOOR_STATUS_LIST_MAXLEN=res.item[0].params.body.DOOR_STATUS_LIST_MAXLEN
          this.aiConfigs.DOOR_STATUS_LIST_NUM=res.item[0].params.body.DOOR_STATUS_LIST_NUM
          this.aiConfigs.Ev_event_code=res.item[0].params.body.Ev_event_code
          this.aiConfigs.Ev_event_cancle_code=res.item[0].params.body.Ev_event_cancle_code
          this.aiConfigs.Block_event_code=res.item[0].params.body.Block_event_code
          this.aiConfigs.Block_event_cancle_code=res.item[0].params.body.Block_event_cancle_code
          this.aiConfigs.Abnormal_event_code=res.item[0].params.body.Abnormal_event_code
          this.aiConfigs.Abnormal_event_cancle_code=res.item[0].params.body.Abnormal_event_cancle_code
        }
        console.log(this.aiConfigs.Abnormal_event_cancle_code)
      }).catch(err => {
        this.hideLoading();
      })
      // }
      return true;
    },
    setParamsConfig(devicenum){
      // let selectedMethod = getItemFromArrayByKey(this.methodList, 'devparamcfgid', this.tabKey);
      // if(selectedMethod) {
      let paramsBody = {
        heartbeat_cycle : this.aiConfigs.heartbeat_cycle,
        version_number : this.aiConfigs.version_number,
        INIT_WITH_VEHICLE_THRESHOLD : this.aiConfigs.INIT_WITH_VEHICLE_THRESHOLD,
        INIT_WITH_VEHICLE_HEAD_THRESHOLD : this.aiConfigs.INIT_WITH_VEHICLE_HEAD_THRESHOLD,
        INIT_WITH_VEHICLE_CHAIR_THRESHOLD : this.aiConfigs.INIT_WITH_VEHICLE_CHAIR_THRESHOLD,
        INIT_WITH_FIGHT_THRESHOLD : this.aiConfigs.INIT_WITH_FIGHT_THRESHOLD,
        INIT_WITH_FISTHEAD_THRESHOLD : this.aiConfigs.INIT_WITH_FISTHEAD_THRESHOLD,
        VEHICLE_QUEUE_SIZE : this.aiConfigs.VEHICLE_QUEUE_SIZE,
        VEHICLE_QUEUE_NUM : this.aiConfigs.VEHICLE_QUEUE_NUM,
        VEHICLE_CHAIR_HEAD_SIZE : this.aiConfigs.VEHICLE_CHAIR_HEAD_SIZE,
        VEHICLE_CHAIR_HEAD_NUM : this.aiConfigs.VEHICLE_CHAIR_HEAD_NUM,
        FIGHT_QUEUE_SIZE : this.aiConfigs.FIGHT_QUEUE_SIZE,
        FIGHT_QUEUE_NUM : this.aiConfigs.FIGHT_QUEUE_NUM,
        FISTHEAD_QUEUE_SIZE : this.aiConfigs.FISTHEAD_QUEUE_SIZE,
        FISTHEAD_QUEUE_NUM : this.aiConfigs.FISTHEAD_QUEUE_NUM,
        BLOCK_DOOR_LIST_MAXLEN : this.aiConfigs.BLOCK_DOOR_LIST_MAXLEN,
        BLOCK_DOOR_LIST_NUM : this.aiConfigs.BLOCK_DOOR_LIST_NUM,
        temp_threshold : this.aiConfigs.temp_threshold,
        door_area : this.aiConfigs.door_area,
        ev_event_enable : this.aiConfigs.ev_event_enable,
        ev_event_cancel : '1',
        abnormal_event_enable : this.aiConfigs.abnormal_event_enable,
        fisthead_enable : this.aiConfigs.fisthead_enable,
        block_event_enable : this.aiConfigs.block_event_enable,
        LISTEN_PORT : this.aiConfigs.LISTEN_PORT,
        mqtt_port : this.aiConfigs.mqtt_port,
        iot_port : this.aiConfigs.iot_port,
        PERSON_STATUS_LIST_MAXLEN : this.aiConfigs.PERSON_STATUS_LIST_MAXLEN,
        PERSON_STATUS_LIST_NUM : this.aiConfigs.PERSON_STATUS_LIST_NUM,
        DOOR_STATUS_LIST_MAXLEN : this.aiConfigs.DOOR_STATUS_LIST_MAXLEN,
        DOOR_STATUS_LIST_NUM : this.aiConfigs.DOOR_STATUS_LIST_NUM,
        Ev_event_code : this.aiConfigs.Ev_event_code,
        Ev_event_cancle_code : this.aiConfigs.Ev_event_cancle_code,
        Block_event_code : this.aiConfigs.Block_event_code,
        Block_event_cancle_code : this.aiConfigs.Block_event_cancle_code,
        Abnormal_event_code : this.aiConfigs.Abnormal_event_code,
        Abnormal_event_cancle_code : this.aiConfigs.Abnormal_event_cancle_code,
      };
      console.log(paramsBody);
      let params = {
        devicenum: devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'PUT',
              url:'/ai',
              body: paramsBody,
            }
          }
        ]
      };
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.$message.success('操作成功');
        }else {
          this.$message.error(res.errormsg||'操作失败');
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
      // }
      return true;
    },
    handleAscend(value, record){
      if(this.getParamsConfig(record.devicenum)){
        if(this.setParamsConfig(record.devicenum)){
          this.$message.success("解除成功");
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>